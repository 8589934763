import React, { useState } from 'react';
import { IoIosArrowForward } from "react-icons/io";
import { CiHome } from "react-icons/ci";
import { FaTwitter } from "react-icons/fa6";
import { IoLogoGoogle } from "react-icons/io";
import { RiFacebookFill } from "react-icons/ri";
import '../static/CSS/login.css'

const Login = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        rememberMe: false,
      });
    
      const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const val = type === 'checkbox' ? checked : value;
        setFormData({ ...formData, [name]: val });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        // Handle login logic here, such as sending data to an API or performing authentication
        console.log(formData);
        // Clear the form after submission
        setFormData({ email: '', password: '', rememberMe: false });
      };

    return (
        <>
            <div className='login'>
                <div className='login-container'>
                    <div className='page-name'>
                        <h1>Login</h1>
                    </div>
                    <div className='navigation-bar'>
                        <ul>
                            <li><a href='/'> <CiHome className='home-icon' /> Home </a><IoIosArrowForward /></li>
                            <li>Login</li>

                        </ul>
                    </div>
                </div>
            </div>
            <section className='login-section'>
                <div className="col-lg-6 offset-lg-3 col-md-10 offset-md-1 col-12">
                    <form className="card login-form" onSubmit={handleSubmit} >
                        <div className="card-body">
                            <div className="title">
                                <h3>Login Now</h3>
                                <p>You can login using your social media account or email address.</p>
                            </div>
                            <div className="social-login">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-12"><button><RiFacebookFill /> Facebook
                                        Login</button>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12"><button><FaTwitter /> Twitter
                                        Login</button></div>
                                    <div className="col-lg-4 col-md-4 col-12"><button><IoLogoGoogle /> Google
                                        Login</button></div>
                                </div>

                            </div>
                            <div className="alt-option">
                                <span>OR</span>
                            </div>
                            <div className="form-group">
                                <label For="email">Email:</label>
                                <input
                                   type="email"
                                   id="email"
                                   name="email"
                                   value={formData.email}
                                   onChange={handleChange}
                                   required
                                />
                            </div>
                            <br />
                            <div className="form-group">
                                <label For="password">Password:</label>
                                <input
                                   type="password"
                                   id="password"
                                   name="password"
                                   value={formData.password}
                                   onChange={handleChange}
                                   required
                                />
                            </div>
                            <br />
                            <div className="d-flex flex-wrap justify-content-between bottom-content ">
                                <div classNames="form-check">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="rememberMe"
                                        />{' '}
                                        Remember me
                                    </label>
                                </div>
                                <a className="lost-pass" href="#">Forgot password?</a>

                            </div>
                            <br />
                            <div className="login-button">
                                <button type="submit">Login</button>
                            </div>
                            <p className="outer-link">Don't have an account? <a href="/register">Register here </a>
                            </p>
                        </div>
                    </form>
                </div>
            </section>
            <br />
        </>
    )
}

export default Login
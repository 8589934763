import React from 'react'
import { Carousel, Card } from 'react-bootstrap';
import '../static/CSS/home.css'
import image1 from '../static/Images/frontend/image1.png'
import image2 from '../static/Images/frontend/image2.png'
import image3 from '../static/Images/frontend/image3.png'
import image4 from '../static/Images/frontend/image4.png'
import image5 from '../static/Images/frontend/image5.png'
import image6 from '../static/Images/frontend/image6.png'
import Button from 'react-bootstrap/Button';
import watch from '../static/Images/frontend/watch.jpg'
import images from '../static/Images/frontend/images.jpg'
import main from '../static/Images/frontend/main.webp'

import 'bootstrap/dist/css/bootstrap.min.css';
const Home = () => {
  return (
    <>
      <div className='section_1'>
        <div className='carousel'>
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={main}
                alt="First slide"
              />

            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={main}
                alt="Second slide"
              />

            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={main}
                alt="Third slide"
              />

            </Carousel.Item>
          </Carousel>
        </div>
        <div className='two-cards'>
          <div className='card-1'>
            <Card style={{ width: '20rem' }} className='card-body'>
              <Card.Body >
                <Card.Text className='text'>
                  <p>Sony WH-1000XM4</p>
                  <h1>₹ 500</h1>
                </Card.Text>
                <Card.Img src={image1} className='card-img' />
              </Card.Body>
            </Card>
          </div>
          <div className='card-2'>
            <Card style={{ width: '20rem' }}  >

              <Card.Body>
                <Card.Title><h1>Weekly Sale!</h1></Card.Title>
                <Card.Text className='text'>
                  <p>Saving up to 50% off all online store items this week.</p>
                </Card.Text>
                <div className='btn'>
                  <Button variant="primary" >Shop Now</Button>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>

      </div>
      <div className='section_2'>
        <div className='categories'>
          <h3>Featured Categories</h3>
          <div className='line'>
            <hr />
          </div>
          <p>There are many variations of passages of Lorem Ipsum available,<br /> but the majority have suffered alteration in some form.</p>
        </div>
      </div>
      <div className='section_3'>
        <div className='card-container'>
          <div className='card'>
            <Card.Body className='body'>
              <div className='title'>
                <Card.Title className='name'>TV & Audios</Card.Title>
                <Card.Link href="#" className='links'>Sony</Card.Link>
                <br />
                <Card.Link href="#" className='links'>LG</Card.Link>
                <br />
                <Card.Link href="#" className='links'>Samsung</Card.Link>
                <br />
                <Card.Link href="#" className='links'>TCL</Card.Link>
              </div>
              <Card.Img src={image1} className='cards-img' />
            </Card.Body>

          </div>
          <div className='card'>
            <Card.Body className='body'>
              <div className='title'>
                <Card.Title className='name'>TV & Audios</Card.Title>
                <Card.Link href="#" className='links'>Sony</Card.Link>
                <br />
                <Card.Link href="#" className='links'>LG</Card.Link>
                <br />
                <Card.Link href="#" className='links'>Samsung</Card.Link>
                <br />
                <Card.Link href="#" className='links'>TCL</Card.Link>
              </div>
              <Card.Img src={image2} className='cards-img' />
            </Card.Body>

          </div>
          <div className='card'>
            <Card.Body className='body'>
              <div className='title'>
                <Card.Title className='name'>TV & Audios</Card.Title>
                <Card.Link href="#" className='links'>Sony</Card.Link>
                <br />
                <Card.Link href="#" className='links'>LG</Card.Link>
                <br />
                <Card.Link href="#" className='links'>Samsung</Card.Link>
                <br />
                <Card.Link href="#" className='links'>TCL</Card.Link>
              </div>
              <Card.Img src={image3} className='cards-img' />
            </Card.Body>

          </div>
        </div>
        <div className='card-container'>
          <div className='card'>
            <Card.Body className='body'>
              <div className='title'>
                <Card.Title className='name'>TV & Audios</Card.Title>
                <Card.Link href="#" className='links'>Sony</Card.Link>
                <br />
                <Card.Link href="#" className='links'>LG</Card.Link>
                <br />
                <Card.Link href="#" className='links'>Samsung</Card.Link>
                <br />
                <Card.Link href="#" className='links'>TCL</Card.Link>
              </div>
              <Card.Img src={image4} className='cards-img' />
            </Card.Body>

          </div>
          <div className='card'>
            <Card.Body className='body'>
              <div className='title'>
                <Card.Title className='name'>TV & Audios</Card.Title>
                <Card.Link href="#" className='links'>Sony</Card.Link>
                <br />
                <Card.Link href="#" className='links'>LG</Card.Link>
                <br />
                <Card.Link href="#" className='links'>Samsung</Card.Link>
                <br />
                <Card.Link href="#" className='links'>TCL</Card.Link>
              </div>
              <Card.Img src={image5} className='cards-img' />
            </Card.Body>

          </div>
          <div className='card'>
            <Card.Body className='body'>
              <div className='title'>
                <Card.Title className='name'>TV & Audios</Card.Title>
                <Card.Link href="#" className='links'>Sony</Card.Link>
                <br />
                <Card.Link href="#" className='links'>LG</Card.Link>
                <br />
                <Card.Link href="#" className='links'>Samsung</Card.Link>
                <br />
                <Card.Link href="#" className='links'>TCL</Card.Link>
              </div>
              <Card.Img src={image6} className='cards-img' />
            </Card.Body>

          </div>
        </div>
      </div>
      <div className='section_4'>
        <div className='container'>
          <Card className='card-container'>

            <Card.Body className='card-body'>
              <div className='body-item'>
                <Card.Title className='body-title'>Smart Watch 2.0</Card.Title>
                <Card.Text>
                  Space Gray Aluminum Case with
                  Black/Volt Real Sport Band
                </Card.Text>
                <Button variant="primary" className='card-btn'>View Details</Button>
              </div>
              <div className='card-img1'>
                <Card.Img src={watch} />
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className='container'>
          <Card className='card-container'>

            <Card.Body className='card-body'>
              <div className='body-item'>
                <Card.Title className='body-title'>Smart Headphone</Card.Title>
                <Card.Text>
                Wireless Headphone with 40mm Drivers
                </Card.Text>
                <Button variant="primary" className='card-btn'>Shop Now</Button>
              </div>
              <div className='card-img1'>
                <Card.Img src={images} />
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  )
}

export default Home
import React from 'react'
import '../static/CSS/footer.css'
import footerlogo from '../static/Images/frontend/footerlogo.svg'
import { IoLogoGooglePlaystore } from "react-icons/io5"
import { RiAppleLine } from "react-icons/ri";
import creditcard1 from '../static/Images/frontend/creditcard1.png';
import mastercard from '../static/Images/frontend/mastercard.png'
import { FaFacebookF } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
const Footer = () => {
  return (
    <>

      <footer>
        <div className='footer'>
          <div className='footer-content'>
            <img src={footerlogo} alt='' className='footer-logo' />
            <div className='footer-text'>
              <p><strong >Subscribe to our Newsletter</strong ><br />
                Get all the latest information, Sales and Offers.</p>
            </div>
            <div className='footer-input'>
              <input placeholder='Email Address Here...' type='email' />
            </div>
            <div className='footer-btn'>
              <button>Subscribe </button>
            </div>
          </div>
          <div className='footer-underline'>
            <hr />
          </div>
          <div className='info-container'>
            <div className='row'>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-footer f-contact">
                  <h3>Get In Touch With Us</h3>
                  <div className='contact-info'>
                    <p className="phone">Phone: +1 (900) 33 169 7720</p>
                    <p>Monday-Friday: </p> <p>9.00 am - 8.00 pm</p>
                    <p>Saturday: </p> <p>10.00 am - 6.00 pm</p>
                    <p className="mail">
                      <a href="mailto:support@shopgrids.com">support@shopgrids.com</a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-footer our-app">
                  <h3>Our Mobile App</h3>
                  <ul className="app-btn">
                    <li>
                      <a href="#">
                        <button className="small-title"><RiAppleLine className='icon' />Download on the <br />
                          App Store</button>
                      </a>
                    </li>
                    <br />
                    <li>
                      <a href="#">

                        <button className="small-title"><IoLogoGooglePlaystore className='icon' />Download on the <br />
                          Google Play</button>
                      </a>
                    </li>
                  </ul>
                </div>

              </div>
              <div className="col-lg-3 col-md-6 col-12">

                <div className="single-footer f-link">
                  <h3>Information</h3>
                  <ul className='info-links'>
                    <li><a href="#">About Us</a></li>
                    <br />
                    <li><a href="#">Contact Us</a></li>
                    <br />
                    <li><a href="#">Downloads</a></li>
                    <br />
                    <li><a href="#">Sitemap</a></li>
                    <br />
                    <li><a href="#">FAQs Page</a></li>
                  </ul>
                </div>

              </div>
              <div className="col-lg-3 col-md-6 col-12">

                <div className="single-footer f-link">
                  <h3>Shop Departments</h3>
                  <ul className='dept-links'>
                    <li><a href="#">Computers & Accessories</a></li>
                    <br />
                    <li><a href="#">Smartphones & Tablets</a></li>
                    <br />
                    <li><a href="#">TV, Video & Audio</a></li>
                    <br />
                    <li><a href="#">Cameras, Photo & Video</a></li>
                    <br />
                    <li><a href="#">Headphones</a></li>
                  </ul>
                </div>

              </div>

            </div>
          </div>
          <div className='footer-underline'>
            <hr />
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="inner-content">
                <div className="row align-items-center">
                  <div className="col-lg-4 col-12">
                    <div className="payment-gateway">
                      <p><strong>We Accept:</strong></p>
                      <img src={creditcard1} alt="#" />
                      <img src={mastercard} alt='#' />
                    </div>
                  </div>
                  <div className="col-lg-4 col-12">
                    <div className="copyright">
                      <p><strong>Designed and Developed by</strong> <br /><a href="#" rel="nofollow" target="_blank">Microtech Solutions</a></p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-12">
                    <div clasNames="socila">

                      <p className='follow'><strong>Follow Us On:</strong></p>

                      <a href="#"><FaFacebookF /></a>
                      <a href="#"><FaTwitter /></a>
                      <a href="#"><FaInstagram /></a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </footer>

    </>
  )
}

export default Footer
import React from 'react'
import { IoIosArrowForward } from "react-icons/io";
import { CiHome } from "react-icons/ci";
import '../static/CSS/register.css'
const Register = () => {
  return (
    <>
      <div className='register'>
        <div className='register-container'>
          <div className='page-name'>
            <h1>Registration</h1>
          </div>
          <div className='navigation-bar'>
            <ul>
              <li><a href='/'> <CiHome className='home-icon' /> Home </a><IoIosArrowForward /></li>
              <li>Registration</li>

            </ul>
          </div>
        </div>
      </div>
      <br />
      <section className='register-section'>
        <div className="col-lg-6 offset-lg-3 col-md-10 offset-md-1 col-12">
          <div className="register-form">
            <div className="title">
              <h3>No Account? Register</h3>
              <p>Registration takes less than a minute but gives you full control over your orders.</p>
            </div>
            <form className="row" >
              <div className="col-sm-6">
                <div className="form-group">
                  <label for="reg-fn">First Name:</label>
                  <input className="form-control" type="text" id="reg-fn" required="" />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label for="reg-ln">Last Name:</label>
                  <input className="form-control" type="text" id="reg-ln" required="" />
                </div>
              </div>

              <div className="col-sm-6">
                <div class="form-group">
                  <label for="reg-email">E-mail Address:</label>
                  <input className="form-control" type="email" id="reg-email" required="" />
                </div>
              </div>
              <div className="col-sm-6">
                <div class="form-group">
                  <label for="reg-phone">Phone Number:</label>
                  <input className="form-control" type="text" id="reg-phone" required="" />
                </div>
              </div>
              <div className="col-sm-6">
                <div class="form-group">
                  <label for="reg-pass">Password:</label>
                  <input className="form-control" type="password" id="reg-pass" required="" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label for="reg-pass-confirm">Confirm Password:</label>
                  <input className="form-control" type="password" id="reg-pass-confirm" required="" />
                </div>
              </div>
              <br />
              <div className="reg-btn">
                <button type="submit">Register</button>
              </div>
              <p className="outer-link">Already have an account?<a href="/login">Login Now </a>
                </p>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

export default Register
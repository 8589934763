
import emp1 from '../static/Images/frontend/emp1.jpg'


const ProfileData = [
    { name: 'Grace Wright', position: 'Founder, CEO', image:emp1 },
    {
        name: 'Taylor Jackson', position: ' Financial Director'
    }
]

export default ProfileData
// import React from "react";

// import { useState } from "react";
// //import axios from "axios";

//  function Admin ()  {


//     const [title, setTitle] = useState("");
//     const [load, setLoad] = useState("");
//     const [reps, setReps] = useState("");




//     const postAPI = (e) => {

//         var myHeaders = new Headers();
//         myHeaders.append("Content-Type", "application/json");
        
//         var raw = JSON.stringify({
//           "title": title,
//           "load": load,
//           "reps": reps
//         });
        
//         var requestOptions = {
//           method: 'POST',
//           headers: myHeaders,
//           body: raw,
//           redirect: 'follow'
//         };
        
//         fetch("http://127.0.0.1:8000/api/workouts/", requestOptions)
//           .then(response => response.text())
//           .then(result => console.log(result))
//           .catch(error => console.log('error', error));
//     }


//     return (
//         <div className="d-flex">
//             <div>

//             </div>
//             <div
//                 style={{
//                     flex: "1 1 auto",
//                     display: "flex",
//                     flexFlow: "column",
//                     height: "100vh",
//                     overflow: "hidden",
//                 }}
//             >

//                 <div style={{ height: "100%" }}>
//                     <div
//                         style={{
//                             padding: "20px 5%",
//                             height: "calc(100% - 64px)",
//                             overflow: "scroll",
//                             background: "whitesmoke",
//                         }}
//                     >
//                         <div
//                             style={{
//                                 display: "grid",
//                                 background: "white",
//                                 gridTemplateColumns: "repeat(1, minmax(200px, 1200px))",
//                             }}
//                         >
//                             <div class="page-wrapper">
//                                 <div class="content container-fluid">
//                                     <div class="row">
//                                         <div class="col-xl-12">

//                                             <div class="page-header" style={{ backgroundColor: '#0b6fa1' }} >
//                                                 <div class="row">
//                                                     <div class="col-sm-12">
//                                                         <h3 class="page-title" style={{ color: "white", textAlign: 'center' }}>City</h3>
//                                                     </div>
//                                                 </div>
//                                             </div>

//                                             <div class="card">
//                                                 <div class="card-body" style={{ backgroundColor: '#55a6cf' }} >
//                                                     <div class="bank-inner-details">
//                                                         <div class="row">

//                                                             <div className="col-12 col-sm-4">
//                                                                 <div className="form-group local-forms">
//                                                                     <label>
//                                                                         Workout title <span className="login-danger">*</span>
//                                                                     </label>
//                                                                     <input required
//                                                                         onChange={e => setTitle(e.target.value)}
//                                                                         className="form-control"
//                                                                         type="text"
//                                                                         placeholder="Enter Name"
//                                                                     />
//                                                                     <br />

//                                                                     <label>
//                                                                         Reps (count) <span className="login-danger">*</span>
//                                                                     </label>
//                                                                     <input required
//                                                                         onChange={e => setReps(e.target.value)}
//                                                                         className="form-control"
//                                                                         type="number"
                                                                       
//                                                                     />
//                                                                     <br />

//                                                                     <label>
//                                                                        Enter load in KG  <span className="login-danger">*</span>
//                                                                     </label>
//                                                                     <input required
//                                                                         onChange={e => setLoad(e.target.value)}
//                                                                         className="form-control"
//                                                                         type="number"
                                                                      
//                                                                     />
//                                                                     <br />
//                                                                 </div>
//                                                             </div>


//                                                         </div>
//                                                     </div>
//                                                     <div class=" blog-categories-btn pt-0">
//                                                         <div class="bank-details-btn ">
//                                                             <button onClick={postAPI} className="btn btn-primary">
//                                                                 Submit
//                                                             </button>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>



//                             </div>

//                         </div>
//                         <footer className="mx-auto my-3 text-center">
//                             <small>&copy; Microtech, 2023. All rights reserved.</small>
//                         </footer>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Admin;
import React from 'react'

const Admin = () => {
  return (
    <div>Admin</div>
  )
}

export default Admin
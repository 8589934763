

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "../src/Pages/Layout";
import Home from "../src/Pages/Home";
import Blogs from "../src/Pages/Blogs";
import Contact from "../src/Pages/Contact";
import Shop from '../src/Pages/Shop';
import NoPage from "../src/Pages/NoPage";
import Pages from '../src/Pages/Pages';
import Admin from '../src/Pages/Admin';
import Navbar from "./Pages/Navbar";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Footer from '../src/Pages/Footer'
import About from "./Pages/About";
function App() {
  return (
    <>
   <BrowserRouter>
   <Layout/>
   <Navbar/>
      <Routes>
        
          <Route path='/' Component={Home} />
          <Route path="/blogs"  Component={Blogs} />
          <Route path="/shop"  Component={Shop} />
          <Route path="/pages"  Component={Pages} />
          <Route path="/contact" Component={Contact} />
          <Route path="/admin" Component={Admin} />
          <Route path="/about" Component={About} />
          <Route path="/login" Component={Login} />
          <Route path="/register" Component={Register} />
          <Route path="*" Component={NoPage} />
       
      </Routes>
      <Footer/>
    </BrowserRouter>
    </>
  );
}

export default App;

import React from 'react'
import '../static/CSS/Layout.css'
import Logo from '../static/Images/frontend/Logo.jpeg'
import { FaRegHeart } from "react-icons/fa";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { FiPhoneCall } from "react-icons/fi";
import { IoSearch } from "react-icons/io5";
const Layout = () => {
  return (
    <>
<div className='header'>
        <img className='header-img' src={Logo} alt='' />
        <div className='header-container'>
          <div className='search-select'>
            <div className='select-position'>
              <select id='select1'>
                <option selected>All</option>
                <option>1</option>
                <option>1</option>
                <option>1</option>
                <option>1</option>
              </select>
            </div>
          </div>
          <div className='serach-input'>
            <input type='text' placeholder='serach here...' />
          </div>
          <div className='search-btn'>
            <button><IoSearch /></button>
          </div>
        </div>
        <div className='call-btn'><FiPhoneCall /></div>
        <div className='contact'><h3>Hotline :</h3>
          <h1>(+100) 123 456 7890</h1>
        </div>
        <div className='icons'>
        <div className='wishlist-icon'>
        <FaRegHeart />
        <span className="wishlist-count">0</span>
        </div>
        <div className='cart-icon'>
        <AiOutlineShoppingCart />
        <span className="cart-count">0</span>
        </div>
        </div>
      </div>
    </>
  )
}

export default Layout
import React from 'react'
import { IoIosArrowForward } from "react-icons/io";
import { CiHome } from "react-icons/ci";
import { CiHeart } from "react-icons/ci";
import img1 from '../static/Images/frontend/img1.jpg'
import img2 from '../static/Images/frontend/img2.jpg'
import img3 from '../static/Images/frontend/img3.jpg'
import img4 from '../static/Images/frontend/img4.jpg'
import img5 from '../static/Images/frontend/img5.jpg'
import { FaStar } from "react-icons/fa";
import { CiStar } from "react-icons/ci";
import '../static/CSS/shop.css'
import { IoIosPricetag } from "react-icons/io";
const Shop = ({ checked, onChange }) => {
  return (
    <>
      <div className='shop'>
        <div className='shop-container'>
          <div className='page-name'>
            <h1>Single Product</h1>
          </div>
          <div className='navigation-bar'>
            <ul>
              <li><a href='/'> <CiHome className='home-icon' /> Home </a><IoIosArrowForward /></li>
              <li><a href='/shop'>Shop </a><IoIosArrowForward /></li>
              <li>Single Product</li>
            </ul>
          </div>
        </div>
      </div>
      <section className='sproduct'>
        <div className='srow'>
          <div className='col-lg-5 col-md-12 col-12'>
            <img className='main-img' src={img1} alt='' />
          </div>
          <div className='small-img-group'>
            <div className='small-img-col'>
              <img className='small-img' src={img2} alt='' />
            </div>
            <div className='small-img-col'>
              <img className='small-img' src={img3} alt='' />
            </div>
            <div className='small-img-col'>
              <img className='small-img' src={img4} alt='' />
            </div>
            <div className='small-img-col'>
              <img className='small-img' src={img5} alt='' />
            </div>
          </div>

        </div>
        <div className='product-details'>
          <div className='product-tittle'>
            <p>GoPro Karma Camera Drone</p>
          </div>
          <div className='subtittle'>
            <h4> <IoIosPricetag /> Drones  :  <a href='#'>Action Cameras</a></h4>
          </div>
          <h3 className="price">₹850  <span>₹945</span></h3>
          <p className="info-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt
            ut labore et dolore magna aliqua.</p>
          <div className='details'>
            <div className='color-options'>
              <label className='tittle-label' for='size'>Choose One</label>
              <div className='color'>
              <label className="round-checkbox">
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={onChange}
                />
                <span className="checkmark"></span>

              </label>
              <label className="round-checkbox">
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={onChange}
                />
                <span className="checkmark1"></span>

              </label>
              <label className="round-checkbox">
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={onChange}
                />
                <span className="checkmark2"></span>

              </label>
              <label className="round-checkbox">
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={onChange}
                />
                <span className="checkmark3"></span>

              </label>
              </div>
            </div>
            <div className='capacity'>
              <label className='tittle-label2' for='size'>Battery Capacity</label>
              <br />
              <select className='battery-capacity'>
                <option selected >5100 mAh</option>
                <option>6000 mAh</option>
                <option>8200 mAh</option>
              </select>
            </div>
          </div>
          <div className='btns'>
            <div className='quantity'>
              <label className='tittle-label3' for='size'>Quantity</label>
              <br />
              <select className='pquantity'>
                <option selected className='qty'>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
              </select>
            </div>
            <div className='wishlist-btn'>
              <button type='submit'><CiHeart className='heart-icon' />To Wishlist</button>
            </div>
            <div className='add-btn'>
              <button type='submit'>Add To Cart</button>
            </div>
          </div>
        </div>
      </section>
      <div className='pdetails'>
        <div className="col-lg-6 col-12">
          <div className="info-body custom-responsive-margin">
            <h4>Details</h4>
            <p className='text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
              irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.</p>
            <br />
            <h4>Features</h4>
            <ul className="features">
              <li>Capture 4K30 Video and 12MP Photos</li>
              <li>Game-Style Controller with Touchscreen</li>
              <li>View Live Camera Feed</li>
              <li>Full Control of HERO6 Black</li>
              <li>Use App for Dedicated Camera Operation</li>
            </ul>
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className="info-body2">
            <h4>Specifications</h4>
            <ul className="normal-list1">
              <li><span>Weight:</span> 35.5oz (1006g)</li>
              <li><span>Maximum Speed:</span> 35 mph (15 m/s)</li>
              <li><span>Maximum Distance:</span> Up to 9,840ft (3,000m)</li>
              <li><span>Operating Frequency:</span> 2.4GHz</li>
              <li><span>Manufacturer:</span> GoPro, USA</li>
            </ul>
            <br />
            <h4>Shipping Options:</h4>
            <ul className="normal-list1">
              <li><span>Courier:</span> 2 - 4 days, $22.50</li>
              <li><span>Local Shipping:</span> up to one week, $10.00</li>
              <li><span>UPS Ground Shipping:</span> 4 - 6 days, $18.00</li>
              <li><span>Unishop Global Export:</span> 3 - 4 days, $25.00</li>
            </ul>
          </div>
        </div>
      </div>
      <div className='review-ratings'>
        <div className="col-lg-4 col-12">
          <div className="single-block give-review">
            <h4>4.5 (Overall)</h4>
            <ul>
              <li >
                <span>5 stars - 38</span>
                <FaStar className='star' />
                <FaStar className='star1' />
                <FaStar className='star1' />
                <FaStar className='star1' />
                <FaStar className='star1' />
              </li>
              <li>
                <span>4 stars - 10</span>
                <FaStar className='star' />
                <FaStar className='star1' />
                <FaStar className='star1' />
                <FaStar className='star1' />
                <CiStar />
              </li>
              <li>
                <span>3 stars - 03</span>
                <FaStar className='star' />
                <FaStar className='star1' />
                <FaStar className='star1' />
                <CiStar />
                <CiStar />

              </li>
              <li>
                <span>2 stars - 01</span>
                <FaStar className='star' />
                <FaStar className='star1' />
                <CiStar />
                <CiStar />
                <CiStar />

              </li>
              <li>
                <span>1 star -  Null</span>
                <FaStar className='star' />
                <CiStar />
                <CiStar />
                <CiStar />
                <CiStar />
              </li>
            </ul>
            {/* <!-- Button trigger modal --> */}
            <div className='review-btn'>
              <button type="submit" >
                Leave a Review
              </button>
            </div>
          </div>
        </div>

        <div className="single-block">
          <div className="reviews">
            <h4 className="title">Latest Reviews</h4>
            {/* <!-- Start Single Review --> */}
            <div className="single-review">

              <div className="review-info">
                <div className='review'>
                  <h4>Awesome quality for the price</h4>
                  <h3>Jacob Hammond
                  </h3>
                  <FaStar className='star' />
                  <FaStar className='star1' />
                  <FaStar className='star1' />
                  <FaStar className='star1' />
                  <FaStar className='star1' />
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                    tempor...</p>
                </div>

              </div>
            </div>
            <br />
            {/* <!-- End Single Review -->
                                <!-- Start Single Review --> */}
            <div className="single-review">

              <div className="review-info">
                <div className='review'>
                  <h4>My husband love his new...</h4>
                  <h3>Alex Jaza
                  </h3>

                  <FaStar className='star' />
                  <FaStar className='star1' />
                  <FaStar className='star1' />
                  <FaStar className='star1' />
                  <CiStar />

                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                    tempor...</p>
                </div>
              </div>
            </div>
            <br />
            {/* <!-- End Single Review -->
                                <!-- Start Single Review --> */}
            <div className="single-review">

              <div className="review-info">
                <div className='review'>
                  <h4>I love the built quality...</h4>
                  <h3>Jacob Hammond
                  </h3>

                  <FaStar className='star' />
                  <FaStar className='star1' />
                  <FaStar className='star1' />
                  <FaStar className='star1' />
                  <FaStar className='star1' />

                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                    tempor...</p>
                </div>
              </div>
            </div>
            {/* <!-- End Single Review --> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default Shop
import React from 'react'
// import { Link } from 'react-router-dom'
import { HiBars3 } from "react-icons/hi2";
import '../static/CSS/navbar.css';
import { SiTwitter } from "react-icons/si";
import { FaFacebookF } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io5";
import { FaSkype } from "react-icons/fa";
const Navbar = () => {
  return (

    <nav>
      <div className='underline'><hr />
        <div className='navbar'>
          <div className="vl">
            <div className='bar'>
              <div className='bar-icon'><HiBars3 /></div>
              <h1>All Categories </h1>
            </div>
          </div>
          <div>
            <ul id="navbar">
              <li>
                <a className="active" href="/">
                  Home
                </a>
              </li>
              <li>
                <a href="/pages" class="dropdown">Pages
                  <div class="dropdown-content">
                    <a href="/about">About</a>
                    <a href="/login">Login</a>
                    <a href="/register">Register</a>
                  </div>
                </a>
              </li>
              <li>
                <a href="/shop" class="dropdown">shop
                  <div class="dropdown-content">
                    <a href="/shop">Product</a>
                    <a href="/cart">Cart</a>
                    <a href="/checkout">Checkout</a>
                  </div>
                </a>
              </li>
              <li>
                <a href="/blogs" class="dropdown">Blogs
                  <div class="dropdown-content">
                    <a href="#">Link 1</a>
                    <a href="#">Link 2</a>
                    <a href="#">Link 3</a>
                  </div>
                </a>
              </li>

              <li>
                <a href="/contact">Contact</a>
              </li>
            </ul>
          </div>
          <div className='social-icons'>
            <h2>Follow Us:</h2>

            <div className='facebook-icon'><FaFacebookF /></div>
            <div className='twitter-icon'><SiTwitter /></div>
            <div className='insta-icon'> <IoLogoInstagram /></div>
            <div className='skype-icon'><FaSkype /></div>


          </div>
        </div>
        <div className='underline'><hr /></div>
      </div>
    </nav>

  )
}

export default Navbar
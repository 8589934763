import React from 'react'
import { IoIosArrowForward } from "react-icons/io";
import { CiHome } from "react-icons/ci";
import '../static/CSS/about.css'
import { FaCirclePlay } from "react-icons/fa6";
import aboutimg from '../static/Images/frontend/aboutimg.jpg'
import ProfileData from './ProfileData';

import emp1 from '../static/Images/frontend/emp1.jpg'
const About = () => {
  return (
    <>
      <div className='about'>
        <div className='about-container'>
          <div className='page-name'>
            <h1>About Us</h1>
          </div>
          <div className='navigation-bar'>
            <ul>
              <li><a href='/'> <CiHome className='home-icon' /> Home </a><IoIosArrowForward /></li>
              <li>About Us</li>

            </ul>
          </div>
        </div>
      </div>

      <div className='about-section'>
        <div className="image-container">
          <img
            src={aboutimg}
            alt="Description of the image"
            className="image"
          />
          <a
            href="https://www.youtube.com/watch?v=r44RKWyfcFw"
            target="_blank"
            rel="noopener noreferrer"
            className="overlay-link"
          >
            <FaCirclePlay />
          </a>
        </div>
        <div className='about-info'>
          <div className='info-text'>
            <h3>ShopGrids - Your Trusted & Reliable Partner.</h3>
            <p >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam id purus at risus pellentesque faucibus a quis eros. In eu fermentum leo. Integer ut eros lacus. Proin ut accumsan leo. Morbi vitae est eget dolor consequat aliquam eget quis dolor. Mauris rutrum fermentum erat, at euismod lorem pharetra nec. Duis erat lectus, ultrices euismod sagittis
            </p>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eius mod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi aliquip ex ea commodo consequat.</p>
          </div>
        </div>




      </div>


      <br />
      <div className='team-profile'>
        <div className='profile-container'>
          <h3>Our Core Team</h3>
          <p>There are many variations of passages of Lorem Ipsum available,<br />
            but the majority have suffered alteration in some form.</p>

        </div>

      </div>

      {ProfileData.map((employee, index) => (
        <div className="profile-card">

          <div className="profile-details">
            <div className='profile'>
              <div key={index} className='emp'>
                <img src={employee.image} />
                <h3>{employee.name}</h3>
                <h4>{employee.position}</h4>
              </div>
            </div>
          </div>
        </div>

      ))}




     

    </>
  )
}

export default About